import React from 'react'
import { Link } from 'react-router-dom'

export const ButtonMenu = ({image, text, optionSelected, option, selected = ''}) => {
    
    return (
        <div className='button-menu__container'>
            <img  onClick={optionSelected} id={option} className={"button-menu__wallpaper " + selected} src={image} alt="imagen" />
            <Link onClick={optionSelected} id={option} className={'button-menu__title'}>{text}</Link>
        </div>
    )
}
