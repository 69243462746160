import React, { useState } from 'react'
import fondo from './../assets/fondo.png'

import pharmacyWallpaper from '../assets/pharmacy-wallpaper.png'
import plantsWallpaper from '../assets/plants-wallpaper.png'
import substratumWallpaper from '../assets/substratum-wallpaper.png'
import suppliesWallpaper from '../assets/supplies-wallpaper.png'
import exoticPlantWallpaper from '../assets/exotic-plants-wallpaper.jpg'

import { ButtonMenu } from '../components/ButtonMenu'

const optionsCatalogue = [{
    image: exoticPlantWallpaper,
    text: 'Exoticas',
    catalogueLink: "https://www.canva.com/design/DAGDlLariEI/Ky2Efw1QkoUpXup7M5oK8g/view?embed"
},
{
    image: pharmacyWallpaper,
    text: 'Farmacia',
    catalogueLink: "https://www.canva.com/design/DAF-zi6YFl8/MNCULr6Tjoz7--jx5p1YmQ/view?embed"
},
{
    image: substratumWallpaper,
    text: "Sustratos",
    catalogueLink: "https://www.canva.com/design/DAFwIl6ZO3A/view?embed"
},
{
    image: suppliesWallpaper,
    text: "Insumos",
    catalogueLink: "https://www.canva.com/design/DAF-zk3PXWI/ew8QaieYrYh7ymqomadW7w/view?embed"
},
{
    image: plantsWallpaper,
    text: 'Plantas',
    catalogueLink: "https://www.canva.com/design/DAF-znDpc10/EwfNcmWKIPZOKChmeq_rpA/view?embed"
}]
export const Catalogue = () => {
    const [catalagueActive, setCatalagueActive] = useState("https://www.canva.com/design/DAF-znDpc10/EwfNcmWKIPZOKChmeq_rpA/view?embed")

    const changeCatalogue = (e) => {
        e.preventDefault();
        setCatalagueActive(e.target.id)
    }
    
    return (
        <div className='catalogue__container'>
            <img src={fondo} alt="" />
            <div className='catalogue__menu'>
                {
                    optionsCatalogue.map(option =>
                        <ButtonMenu 
                            image={option.image} 
                            text={option.text} 
                            optionSelected={changeCatalogue} 
                            option={option.catalogueLink}
                        />
                    )
                }
            </div>
            <iframe
                loading="lazy"
                title="catalago"
                className='catalogue' src={catalagueActive}
                allowFullScreen="allowfullscreen"
                allow="fullscreen">
            </iframe>
        </div>

    )
}
