import React from 'react';
import ReactWhatsappButton from "react-whatsapp-button";
import { NavigationBar } from './screens/NavigationBar';
import { Catalogue } from './screens/Catalogue';

function App() {
  return (
    <div style={{ with: '100%', height: '100%' }}>
      <NavigationBar />
      <div style={{ with: '100%', height: '80%' }}>
        <Catalogue />
      </div>
      <ReactWhatsappButton
        countryCode="52"
        phoneNumber="4791423790"
        chatMessage="Hola, Me gustaria realizar un pedido"
        placeholder="Para mas información, contactanos"
      />
    </div>
  );
}

export default App;
