import React, { useState } from 'react'
import logo from './../assets/logo.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export const NavigationBar = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <div>
            <div className="navbar__main-container" id="mynavbar__main-container">
                <div className='navbar__brand-container'>
                    <img src={logo} className="navbar__logo" alt="" />
                    <Link className="navbar__brand" href="#">Floriver</Link>
                </div>
                <Link className={`navbar__link oculto ${isNavCollapsed && `navbar__link-hide`}`} to="#">Nosotros</Link>
                <Link className={`navbar__link oculto ${isNavCollapsed && `navbar__link-hide`}`} to="#">Contacto</Link>
                <Link className="icon oculto" onClick={handleNavCollapse}> <FontAwesomeIcon icon={faBars} /></Link>
            </div>
        </div>
    )
}
